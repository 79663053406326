<template>
  <div class="user">
    <div class="block-quote">
      <el-form :inline="true">
        <el-form-item label="续租设备:">
          <el-input
            style="width: 200px"
            v-model="box_name"
            clearable
            placeholder="请输入续租设备:"
          ></el-input>
        </el-form-item>

        <el-form-item label="续租订单号:">
          <el-input
            style="width: 200px"
            v-model="order"
            clearable
            placeholder="续租订单号"
          ></el-input>
        </el-form-item>
        <el-form-item label="时间:">
          <el-date-picker
            v-model="time"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item style="float: right">
          <el-button type="primary" icon="el-icon-search" @click="searchinfo"
            >搜索</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <page-table
      ref="dataTable"
      :data="userList"
      @changeCurrentPage="changeCurrent"
    >
      <el-table-column label="序号" align="center">
        <template slot-scope="scope">
          <span>{{
            (page.currentPage - 1) * page.pageSize + scope.$index + 1
          }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="name" label="设备租赁号" align="center">
      </el-table-column>
      <el-table-column label="推荐人" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.use_pid == null">无</span>
          <span v-else>{{ scope.row.use_pid }}</span>
        </template>
      </el-table-column>

      <el-table-column label="推荐人租赁号" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.pid == null">无</span>
          <span v-else>{{ scope.row.pid }}</span>
        </template>
      </el-table-column>

      <el-table-column label="租赁人数" align="center">
        <template slot-scope="scope">
          <el-link @click="handleClick(scope.row)">{{
            scope.row.count
          }}</el-link>
        </template>
      </el-table-column>
      <el-table-column label="租赁套餐" align="center">
        <template slot-scope="scope">
          <el-link v-if="scope.row.relet_type > 0">是</el-link>
          <el-link v-else>否</el-link>
        </template>
      </el-table-column>

      <el-table-column prop="boxTeam" label="所属团队" align="center">
      </el-table-column>
      <el-table-column prop="order" label="订单" align="center">
      </el-table-column>

      <el-table-column prop="order_price" label="金额" align="center">
      </el-table-column>
      <el-table-column prop="pay_time" label="购买时间" align="center">
        <template slot-scope="scope">
          <span> {{ scope.row.created_at }}</span>
        </template>
      </el-table-column>
    </page-table>
    <el-dialog
      title="账户信息"
      :visible.sync="dialogVisible"
      width="600px"
      :close-on-click-modal="false"
      @close="close"
    >
      <el-table ref="dataTable" :data="List" border>
        <el-table-column label="序号" align="center">
          <template slot-scope="scope">
            <span>{{
              (page.currentPage - 1) * page.pageSize + scope.$index + 1
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="uid" label="用户id" align="center">
        </el-table-column>

        <el-table-column prop="phone" label="联系方式" align="center">
        </el-table-column>
        <el-table-column prop="share" label="分润占比" align="center">
        </el-table-column>
      </el-table>
    </el-dialog>
    <!-- 新增编辑弹窗 -->
    <edit-data ref="editData" />
  </div>
</template>

<script>
import { boxOrderInfo, shareInfo } from "@/request/api";
import moment from "moment";
import pageTable from "@/components/pageTable.vue";
import { checkPermission } from "@/utils/permissions";
import editData from "./components/editData.vue";

export default {
  name: "user",
  components: {
    pageTable,
    editData,
  },
  data() {
    return {
      order: "",
      box_name: "",
      uid: "",
      dialogVisible: false,
      time: "",
      userList: [],
      List: [],

      page: {
        //分页信息
        currentPage: 1, //当前页
        pageSize: 10, //每页条数
        total: 0, //总条数
      },
    };
  },
  watch: {
    time(newVal) {
      if (newVal == null) {
        this.time = [];
      }
    },
  },
  filters: {
    Time(e) {
      //处理时间
      return moment(parseInt(e)).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  created() {
    this.getUserList(); //获取用户列表
  },
  mounted() {},
  computed: {},
  methods: {
    close() {
      this.dialogVisible = false;
    },
    handleClick(row) {
      this.box_name = row.name;
      this.getList();
    },
    getList() {
      let params = {
        token: sessionStorage.getItem("token"),
        box_name: this.box_name,
      };
      shareInfo(params).then((res) => {
        this.List = res.data.data;
      });
      this.dialogVisible = true;
    },
    showtable(row) {
      let rowData = row;
      this.$refs.editData.show(JSON.parse(JSON.stringify(rowData)));
    },
    checkPermission,
    // 切换分页
    changeCurrent(page, size) {
      this.page.currentPage = page;
      this.page.pageSize = size;
      this.getUserList();
    },
    searchinfo() {
      this.page.currentPage = 1;
      let token = sessionStorage.getItem("token");
      this.token = token;
      let params = {
        page: 1,
        limit: this.page.pageSize,
        token: sessionStorage.getItem("token"),
        order: this.order,
        box_name: this.box_name,
        uid: this.uid,
        time1: this.time[0],
        time2: this.time[1],
      };
      boxOrderInfo(params).then((res) => {
        this.page.total = res.data.count;
        this.userList = res.data.data;
        this.$refs.dataTable.setPageInfo({
          total: this.page.total,
        });
      });
    },
    getUserList() {
      let token = sessionStorage.getItem("token");
      this.token = token;
      let params = {
        page: this.page.currentPage,
        limit: this.page.pageSize,
        token: sessionStorage.getItem("token"),
        order: this.order,
        box_name: this.box_name,
        uid: this.uid,
        time1: this.time[0],
        time2: this.time[1],
      };
      boxOrderInfo(params).then((res) => {
        this.page.total = res.data.count;
        this.userList = res.data.data;
        this.$refs.dataTable.setPageInfo({
          total: this.page.total,
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
